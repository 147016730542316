<template>
  <v-row>
    <v-col :cols="4" v-if="!isRouteProfile">
      <v-btn
        type='submit'
        color='secondary'
        :to="{ name: back }"
        outlined
        depressed
        block
        x-large
        :disabled='loading'>
        <v-icon small>mdi-arrow-left</v-icon><span class="ml-1">Back</span>
      </v-btn>
    </v-col>
    <v-col :cols="isRouteProfile ? 12 : 8">
      <v-btn
        type='submit'
        color='secondary'
        block
        depressed
        x-large
        :disabled='loading || !valid'
        :loading='loading'>
        <template v-slot:loader>
          <span>Loading...</span>
        </template>
        <span class="mr-1" v-if="!isEmployer">Next </span>
        <span class="mr-1" v-else>Submit</span>
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    isEmployer: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    back: {
      type: String,
      default: 'false'
    },
    valid: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isRouteProfile () {
      return this.$route.name === 'profileDetails'
    }
  }
}
</script>

<style scoped>

</style>
