<template>
  <v-card class='mb-5 form-container py-5' :loading="loading" elevation="0" color="transparent">
    <h2 class='create-header mb-5 text-center'>🍯 Select Topics</h2>
    <p class='create-header mb-5 text-center'>Select <b>at least 1</b> topics. People hiring in these categories will reach you first</p>
    <v-form ref='selectCategories' v-model='valid' lazy-validation @submit.prevent='validate()' id='select-categories-form' class='v-text-field--rounded form' loading>
      <v-row>
        <v-col v-if="$apollo.queries.categories.loading">
          <v-card>
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          </v-card>
        </v-col>
        <v-col v-else>
<!--            Implement when all things are done -->
<!--            <div v-for="(value, item, i) in categ" :key="i" class="mb-5">-->
<!--              <p class="mb-0"><b>{{ item }}</b></p>-->
<!--              <v-chip-group column active-class="success&#45;&#45;text" multiple v-model="user.selectedCategories[Object.keys(user.selectedCategories)[i]]">-->
<!--                <v-chip v-for="(a, i) in value" :key="i" filter outlined label :value="a" filter-icon="mdi-bee">-->
<!--                  {{ a.name }}-->
<!--                </v-chip>-->
<!--              </v-chip-group>-->
<!--            </div>-->
          <v-chip-group column active-class="success--text" multiple v-model="userSelected">
            <v-chip v-for="(item, i) in userCategories" :key="i" filter outlined label :value="item.id" filter-icon="mdi-bee">
              {{ item.name }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <v-alert text outlined color='error' icon='mdi-fire' v-if='error && newCategories.length === 0' :class="{'mb-12' : $vuetify.breakpoint.smAndDown}">
        {{ error }}
      </v-alert>
      <actions :loading="loading" back="educationDetails" />
    </v-form>
  </v-card>
</template>

<script>
import categ from '../../../../public/categories'
import Actions from '../../../components/Onboarding/Actions'
const CATEGORIES = require('../../../graphql/queries/categories.graphql')
const UPDATE_USER = require('../../../graphql/updateUser.graphql')

export default {
  apollo: {
    categories: {
      query: CATEGORIES,
      variables: {
        query: {}
      }
    }
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      valid: true,
      loading: false,
      newCategories: [],
      user: {
        selectedCategories: {}, // NEW DESIGN will use it later
        categories: []
      },
      categ: categ, // NEW DESIGN will use it later
      error: null
    }
  },
  computed: {
    userCategories () {
      return this.categories && this.categories.map(item => item.term)
    },
    userSelected: {
      get () {
        return this.currentUser.profile.project_category.map(item => item.id) || []
      },
      set (val) {
        this.newCategories = val
        // this.currentUser.profile.project_category = [val]
      }
    }
  },
  mounted () {
    // NEW DESIGN will use it later
    Object.keys(categ).map(category => {
      this.$set(this.user.selectedCategories, category, [])
    })
    // this.$emit('reFetch')
  },
  methods: {
    validate () {
      if (this.$refs.selectCategories.validate()) {
        this.submitUser()
      }
    },
    async submitUser () {
      this.loading = true
      try {
        if (this.newCategories.length > 0) {
          await this.$apollo.mutate({
            mutation: UPDATE_USER,
            variables: {
              data: {
                categories: this.newCategories,
                onboarding_done: true
              }
            }
          })
          await this.$emit('reFetch')
          await this.$store.dispatch('setOnboarding', '1')
          await this.$router.replace({ name: 'feed' })
        } else {
          this.error = 'Select at least 1 category'
        }
      } catch (e) {
        this.loading = false
        console.log(e)
        this.error = e
      } finally {
        this.loading = false
      }
    }
  },
  components: {
    Actions
  }
}
</script>
<style lang='scss' scoped>
  .form-container {
    border-radius: 4px;
    .form {
      padding: 0 1rem 0;
    }
  }
  .access-header {
    text-align: center;
    padding: 1.5rem 0;
    font-weight: bold;
    text-transform: uppercase;
  }
</style>
