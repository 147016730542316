export default {
  'Finance, Management, & Ops': [
    {
      name: 'Accountant',
      id: 74
    },
    {
      name: 'Administrative Assistant/Executive Assistant',
      id: 17
    },
    {
      name: 'Claims/Insurance',
      id: 92
    },
    {
      name: 'Data Analyst',
      id: 75
    },
    {
      name: 'Finance',
      id: 34
    },
    {
      name: 'HR Coordinator',
      id: 76
    },
    {
      name: 'Management',
      id: 14
    },
    {
      name: 'Recruiter',
      id: 77
    },
    {
      name: 'Supply Chain Management',
      id: 86
    }
  ],
  'Sales & Marketing': [
    {
      name: 'Account Manager / Customer Support',
      id: 7
    },
    {
      name: 'Campus Rep/Brand Ambassador',
      id: 3
    },
    {
      name: 'Digital Marketing & Social Media',
      id: 12
    },
    {
      name: 'Event Coordinator',
      id: 15
    },
    {
      name: 'Public Relations',
      id: 89
    },
    {
      name: 'Sales / Account Executive',
      id: 84
    },
    {
      name: 'Sales Operations',
      id: 83
    }
  ],
  'Arts & Fashion': [
    {
      name: 'Architecture',
      id: 88
    },
    {
      name: 'Merchandiser',
      id: 71
    },
    {
      name: 'Photography',
      id: 73
    },
    {
      name: 'Textile & Apparel Designer',
      id: 72
    },
    {
      name: 'Video Production',
      id: 19
    }
  ],
  Education: [
    {
      name: 'Camp Counselor',
      id: 2
    },
    {
      name: 'Education Assistant',
      id: 70
    },
    {
      name: 'Teacher',
      id: 29
    },
    {
      name: 'Tutor',
      id: 69
    }
  ],
  'Law & Journalism': [
    {
      name: 'Copywriter',
      id: 79
    },
    {
      name: 'Journalist',
      id: 1
    },
    {
      name: 'Legal Assistant or Paralegal',
      id: 68
    }
  ],
  'Product & Engineering': [
    {
      name: 'Data Scientist',
      id: 67
    },
    {
      name: 'Engineering (Other)',
      id: 9
    },
    {
      name: 'Graphic or UI Designer',
      id: 11
    },
    {
      name: 'Product Manager',
      id: 80
    },
    {
      name: 'Project Manager',
      id: 22
    },
    {
      name: 'Quality Assurance',
      id: 81
    },
    {
      name: 'Software Engineer',
      id: 82
    }
  ],
  'Networking Opportunities': [
    {
      name: 'Events',
      id: 87
    }
  ],
  'Health & Science': [
    {
      name: 'Exercise Instructor/Sports Coach',
      id: 10
    },
    {
      name: 'Nurse',
      id: 13
    },
    {
      name: 'Pharmaceuticals',
      id: 78
    },
    {
      name: 'Research & Development',
      id: 24
    },
    {
      name: 'Social Work/Psychology',
      id: 91
    }
  ]
}
