<template>
  <div>
    <action-buttons :loading="loading" v-if="!mdScreen" :back="back" :valid="valid" :is-employer="isEmployer" />
    <v-footer fixed v-else>
      <action-buttons :loading="loading" :back="back" :valid="valid" :is-employer="isEmployer" />
    </v-footer>
  </div>
</template>

<script>
import ActionButtons from './ActionButtons'
import breakPoints from '../../mixins/breakPoints'
export default {
  props: {
    isEmployer: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    valid: {
      type: Boolean,
      default: true
    },
    back: {
      type: String,
      default: ''
    }
  },
  components: {
    ActionButtons
  },
  mixins: [breakPoints]
}
</script>
